<template>
  <b-row>
    <b-col
      md="12"
    >
      <b-card
        title="Réseau de transporteurs"
        no-body
      >
        <b-card-body>
          <b-row>
            <b-col
              md="12"
            >
              <b-card
                title="Réseau de transporteurs"
                no-body
              >
                Réseau de transporteurs
              </b-card>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                class="float-right mb-1"
                onclick="history.back()"
              >
                <feather-icon icon="ArrowLeftCircleIcon" />
                Retour
              </b-button>
              <b-button
                v-if="deleteItems.transporteur_ids.length>0"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-modal.supprimer-selection
                v-b-modal.suppression
                type="submit"
                variant="danger"
                class="ml-1"
                size="sm"
              >
                Supprimer la sélection
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-modal.modal-prevent-closing
                type="submit"
                variant="success"
                class="mr-1"
                size="sm"
              >
                Ajouter au réseau de transporteurs
              </b-button>
            </b-col>
          </b-row>

          <div class="d-flex justify-content-between  flex-wrap">
            <!-- sorting  -->
            <b-form-group
              label="Trier"
              label-size="sm"
              label-align-sm="left"
              label-cols-sm="2"
              label-for="sortBySelect"
              class=" mb-md-0"
            >
              <b-input-group
                size="sm"
              >
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                >
                  <template #first>
                    <option value="">
                      aucun
                    </option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                >
                  <option :value="false">
                    Croissant
                  </option>
                  <option :value="true">
                    Décroissant
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>

            <!-- filter -->
            <b-form-group
              label="Filtrer"
              label-cols-sm="2"
              label-align-sm="left"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Rechercher"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Effacer
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
        </b-card-body>

        <b-table
          striped
          hover
          responsive
          small
          :busy="loader"
          class="position-relative"
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #table-busy>
            <div class="d-flex justify-content-center mb-3">
              <b-spinner
                style="width: 2rem; height: 2rem;"
                variant="primary"
                label="Large Spinner"
                type="grow"
                class="mt-2"
              />
              <span class="sr-only">Chargement en cours...</span>
            </div>
          </template>
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <feather-icon
              v-b-modal.suppression
              icon="TrashIcon"
              @click.prevent="getDiffusionId(data.item.id)"
            />
          </template>
          <!-- Column: Actions -->
          <template #cell(choix)="data">
            <b-form-checkbox
              v-model="deleteItems.transporteur_ids"
              :value="`${data.item.id}`"
            />
          </template>
        </b-table>

        <!-- form modal -->
        <b-modal
          id="modal-prevent-closing"
          ref="my-modal"
          no-close-on-backdrop
          button-size="sm"
          title="Ajouter au réseau de transporteurs"
          cancel-title="Fermer"
          ok-title="Ajouter"
          cancel-variant="outline-secondary"
          @ok="handleOk"
        >
          <validation-observer
            ref="ValidationRules"
          >
            <form
              ref="form"
              @submit.prevent="handleSubmitModal"
            >

              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="transporteur_ids"
                  rules="required"
                >
                  <v-select
                    ref="ids-input"
                    v-model="form.transporteur_ids"
                    multiple
                    placeholder="Réseau de transporteurs"
                    :options="users"
                    :reduce="(users) => users.id"
                    label="lastname"
                  >
                    <small class="text-danger">{{ errors[0] }}</small>
                  </v-select>
                </validation-provider>

              </b-form-group>
            </form>
          </validation-observer>

        </b-modal>

        <!-- form modal -->
        <b-modal
          id="suppression"
          ref="my-modal"
          cancel-title="Non"
          ok-title="Oui"
          title="Retirer de la liste de diffusion"
          modal-class="modal-danger"
          ok-variant="danger"
          button-size="sm"
          cancel-variant="outline-secondary"
          no-close-on-backdrop
          @ok="handleDeleteOk"
        >
          <form @submit.prevent="handleDeleteSubmitModal">
            <h6>Etes-vous sûr de vouloir supprimer la sélection?</h6>

          </form>
        </b-modal>

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

          <!-- page length -->
          <b-form-group
            label="Par Page"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0 pagination-center"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-card-body>

      </b-card>
    </b-col>

  </b-row>
</template>

<script>
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash'

import {
  BRow, BFormCheckbox, BCol, BTable, VBTooltip, BFormGroup, BFormSelect, BPagination, BInputGroup, BInputGroupAppend, BButton, BCardBody, BCard, BFormInput, BModal, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  onMounted, reactive, ref, watch,
} from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import useDiffusion from '@/services/affreteur/offresDiffusion'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
} from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BFormCheckbox,
    BCol,
    BCard,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BModal,
    vSelect,
    BSpinner,

  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      required,
    }
  },
  setup() {
    const {
      getUsersByTransporteurSociete, handlePostDiffusion, listeDiffusion, deleteDiffusion, users, diffusion, loader,
    } = useDiffusion()
    const fields = [
      {
        key: 'choix', stickyColumn: true, label: '',
      },
      { key: 'lastname', label: 'Désignation', sortable: true },
      { key: 'role', label: 'Profil', sortable: true },
      { key: 'actions', label: 'Actions' },
    ]

    const items = diffusion

    const perPage = 10
    const pageOptions = [3, 5, 10]
    const totalRows = ref(1)
    const currentPage = ref(1)
    const sortBy = ''
    const sortDesc = false
    const sortDirection = 'asc'
    const filter = null
    const filterOn = []

    onMounted(() => {
      getUsersByTransporteurSociete()
      listeDiffusion()
      // Set the initial number of items
      totalRows.value = items.length
    })
    const form = reactive({
      transporteur_ids: [],
    })
    const deleteItems = reactive({
      transporteur_ids: [],
    })

    const onFiltered = filteredItems => {
      // Trigger pagination to update the number of buttons/pages due to filtering
      totalRows.value = filteredItems.length
      currentPage.value = 1
    }
    const removeDiffusion = async data => {
      await deleteDiffusion(data).then(() => {
        deleteItems.transporteur_ids = []
        getUsersByTransporteurSociete()
      })
      // console.clear()
    }
    const getDiffusionId = id => {
      form.transporteur_ids.push(id)
    }
    // const itemsSelected = ref([])
    // eslint-disable-next-line no-unused-vars
    watch(() => _.cloneDeep(deleteItems.transporteur_ids), value => {
      form.transporteur_ids = deleteItems.transporteur_ids
    })
    const handleDiffusion = async () => {
      await handlePostDiffusion(form).then(() => {
        form.transporteur_ids = {}
      })
    }
    return {
      handleDiffusion,
      form,
      users,
      onFiltered,
      items,
      fields,
      perPage,
      totalRows,
      pageOptions,
      currentPage,
      sortBy,
      sortDesc,
      sortDirection,
      filter,
      filterOn,
      loader,
      getDiffusionId,
      removeDiffusion,
      deleteItems,

    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  methods: {
    handleDeleteOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleDeleteSubmitModal()
    },
    handleDeleteSubmitModal() {
      // Exit when the form isn't valid

      this.removeDiffusion(this.form)
      // Hide the modal manually
      this.$nextTick(() => {
        if (this.$refs['ids-input']) {
          this.$refs['ids-input'].reset()
        }
        this.$refs['my-modal'].toggle('#toggle-btn')
      })
    },

    handleOk(bvModalEvt) {
    // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmitModal()
    },
    handleSubmitModal() {
      // Exit when the form isn't valid
      this.$refs.ValidationRules.validate().then(success => {
        if (success) {
          // Push the name to submitted names
          this.handleDiffusion()
          // Hide the modal manually
          this.$nextTick(() => {
            this.$refs['my-modal'].toggle('#toggle-btn')
          })
        }
      })
    },
  },

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
